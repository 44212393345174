/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/no-unescaped-entities */
import React, {useEffect, useRef, useState} from 'react';

import Navbar from '@components/Navbar';
import Footer from '@components/Footer';
import AppButton, {LinkBtn} from '@components/AppButton';
import Form from '@components/Mail';

import HeroImage from '@images/hero.webp';
import Avatar from '@images/MyAvatar.png';
import react from '@images/react.png';
import javascript from '@images/javascript.png';
import typescript from '@images/typescript.png';
import sass from '@images/sass.png';
import tailwind from '@images/tailwind.png';
import materialUi from '@images/material-ui.png';
import html from '@images/html.png';
import git from '@images/git.png';
import reactNative from '@images/react_native.png';
import firebase from '@images/firebase.png';
import node from '@images/node.png';
import express from '@images/express.png';
import mongodb from '@images/mongodb.png';
import python from '@images/python.png';
import etc from '@images/etc.png';
import neat from '@images/neat.png';
import deep from '@images/deep.png';
import mentor from '@images/mentor.png';
import lukim from '@images/lukim.png';
import gbv from '@images/gbv.png';
import upper from '@images/upper.webp';
import mun from '@images/mun.webp';
import contact from '@images/contact.png';
import css from '@images/css.png';
import next from '@images/next.png';
import about from '@images/about.png';
import work from '@images/work.png';
import map from '@images/map.png';
import mobile from '@images/smartphone.png';
import contactIcon from '@images/contact-icon.png';

const images = [
  {img: react, maxH: 'max-h-[50px] sm:max-h-[60px]'},
  {img: next, maxH: 'max-h-[50px] sm:max-h-[65px]'},
  {img: reactNative, maxH: 'max-h-[65px] sm:max-h-[70px]'},
  {img: typescript, maxH: 'max-h-[70px] sm:max-h-[85px]'},
  {img: javascript, maxH: 'max-h-[120px]'},
  {img: python, maxH: 'max-h-[100px]'},
  {img: tailwind, maxH: 'max-h-[40px] sm:max-h-[55px]'},
  {img: materialUi, maxH: 'max-h-[60px] sm:max-h-[75px]'},
  {img: sass, maxH: 'max-h-[80px] sm:max-h-[100px]'},
  {img: firebase, maxH: 'max-h-[50px] sm:max-h-[60px]'},
  {img: mongodb, maxH: 'max-h-[50px] sm:max-h-[70px]'},
  {img: node, maxH: 'max-h-[60px] sm:max-h-[90px]'},
  {img: express, maxH: 'max-h-[70px]'},
  {img: git, maxH: 'max-h-[50px] sm:max-h-[70px]'},
  {img: html, maxH: 'max-h-[80px]'},
  {img: css, maxH: 'max-h-[80px]'},
  {img: etc, maxH: 'max-h-[40px] sm:max-h-[50px]'},
];

const Home = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const aboutRef = useRef(null);
  const workRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    if (activeIndex === images.length) setActiveIndex(0);
    const timer = setInterval(() => {
      setActiveIndex(activeIndex + 1);
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, [activeIndex]);

  return (
    <main>
      <div className='bg-color-bg w-[100%]'>
        <div className='max-w-[1440px] mx-auto px-[5vw] pb-[70px]'>
          <Navbar
            aboutRef={aboutRef}
            workRef={workRef}
            contactRef={contactRef}
          />
          <div className='py-[20px] flex flex-col gap-[50px] pt-[160px] lg:pt-[200px] sm:pb-[50px] lg:pb-[120px] lg:flex-row lg:gap-[20px]'>
            <div className='lg:w-[50%] flex flex-col justify-end'>
              <div className='flex'>
                <h2 className='font-sans font-[700] leading-[44px] text-[36px] text-[#b41c4b] sm:leading-[68px] sm:text-[54px] mb-[20px]'>
                  Hi,
                  <span className='text-[#e8c24c]'> i am </span>
                </h2>
                <div className='animate-wave text-6xl mt-[-7px]'> 👋</div>
              </div>
              <h1 className='font-sans font-[700] leading-[44px] text-[36px] text-[#693a70] sm:leading-[68px] sm:text-[64px]'>
                Saugat Dhimal
              </h1>
              <h2 className='font-sans font-[700] leading-[44px] text-[36px] text-[#693a70] sm:leading-[68px] sm:text-[64px]'>
                Software Developer
              </h2>
              <div className='flex flex-row gap-[12px] mt-[28px] justify-start items-center'>
                <AppButton to={contactRef} text='Contact Me' />
                <AppButton to={workRef} text='View Projects' />
              </div>
            </div>
            <div className='flex align-center justify-center lg:max-w-[50%]'>
              <img src={HeroImage} alt='hero' />
            </div>
          </div>
          <div className='pt-[100px]' ref={aboutRef}>
            <div className='flex justify-center'>
              <img
                src={Avatar}
                alt='avatar'
                className='max-h-[250px] max-w-[250px] sm:max-h-[375px] sm:max-w-[375px] mt-[50px] lg:mt-[0]'
              />
            </div>
            <div className='flex gap-[20px] justify-center'>
              <img src={about} alt='about' className='max-h-[50px]' />
              <h2 className='self-end font-inter font-[500] text-[30px] text-[#693a70] text-center leading-[62px]'>
                ABOUT
              </h2>
            </div>
            <h2 className='font-sans font-[700] text-[36px] leading-[44px] text-[#b41c4b] lg:mt-[0] text-center sm:text-[48px] sm:leading-[52px]'>
              Personal
              <span className='text-[#e8c24c]'> Info</span>
            </h2>
            <p className='font-inter font-[600] text-[16px] sm:text-[20px]  leading-[44px] text-color-lt-grey mb-[56px] lg:mt-[0] text-center sm:leading-[52px]'>
              Get to know more about me
            </p>
            <div className='flex flex-col gap-[10px]'>
              <div>
                <h2 className='font-inter font-[600] text-[26px] leading-[44px] text-[#693a70] sm:leading-[62px]'>
                  Hello!
                </h2>
                <p className='font-inter font-[400] text-[16px] leading-[24px] text-color-text sm:text-[18px] sm:leading-[32px]'>
                  My name is Saugat Dhimal. I am a passionate software developer
                  who is always eager to learn new technologies and to use the
                  latest tools to create excellent products and solve problems.
                  I've been writing code for software for more than two years.
                  I was currently employed as a Software Engineer for Nepware
                  , where I was primarily responsible for the front-end aspects
                  of projects.
                </p>
                <p className='font-inter font-[400] text-[16px] leading-[24px] text-color-text mt-[20px] mb-[25px] sm:text-[18px] sm:leading-[32px]'>
                  I will love to hear from you. Whether it's a project, job
                  opportunity, or just a chat. Feel free to contact me.
                </p>
              </div>
              <div>
                <h2 className='font-inter font-[600] text-[26px] leading-[44px] text-[#693a70] mb-[20px] sm:mb-[0] sm:leading-[62px] '>
                  Skills
                </h2>
                <div className='flex flex-wrap items-center justify-center gap-[20px]'>
                  {images.map((item, index) => (
                    <img
                      key={JSON.stringify(item.img)}
                      src={item.img}
                      alt=''
                      className={`${item.maxH} ${
                        activeIndex === index ? 'animate-ani opacity-100' : ''
                      }`}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='max-w-[1440px] mx-auto px-[5vw] pt-[100px]' ref={workRef}>
        <div className='flex gap-[20px] justify-center'>
          <img src={work} alt='work' className='max-h-[50px]' />
          <h2 className='self-end font-inter font-[500] text-[30px] text-[#693a70] text-center leading-[62px]'>
            WORK
          </h2>
        </div>
        <h2 className='font-sans font-[700] text-[36px] leading-[44px] text-[#b41c4b] lg:mt-[0] text-center sm:text-[48px] sm:leading-[52px]'>
          Projects
          <span className='text-[#e8c24c]'> Info</span>
        </h2>
        <p className='font-inter font-[600] text-[16px] sm:text-[20px] mt-[12px] sm:mt-[0] text-color-lt-grey mb-[56px] lg:mt-[0] text-center sm:leading-[52px]'>
          Some of the projects to which I have contributed are listed below.
        </p>
        <div className='flex lg:justify-between flex-wrap'>
          <div className='lg:w-[45%] mb-[56px]'>
            <img src={lukim} alt='' className='max-h-[420px] mx-auto' />
            <h3 className='font-inter font-[600] text-[25px] leading-[64px] text-[#b41c4b] border-b-4 w-fit border-[#e8c24c]'>
              Lukim Gather Website
            </h3>
            <p className='my-[20px] font-inter font-[400] text-[16px] leading-[24px] text-color-text sm:text-[18px] sm:leading-[32px]'>
              A phone application being developed by Catalpa International which
              will help PNG to monitor its protected areas. The new system will
              allow Rangers, who work in isolated areas, to quickly record and
              report harmful and illegal activities.
            </p>
            <div className='flex gap-6'>
              <LinkBtn to='https://lukimgather.org' />
              <LinkBtn to='https://github.com/undp-png/lukim-gather-website' text='See Code' />
            </div>
          </div>
          <div className='lg:w-[45%] mb-[56px]'>
            <img src={neat} alt='' className='max-h-[420px] mx-auto' />
            <h3 className='font-inter font-[600] text-[25px] leading-[64px] text-[#b41c4b] border-b-4 w-fit border-[#e8c24c]'>
              Neat Plus
            </h3>
            <p className='my-[20px] font-inter font-[400] text-[16px] leading-[24px] text-color-text sm:text-[18px] sm:leading-[32px]'>
              The Nexus Environmental Assessment Tool (NEAT+) is a rapid and
              simple project-level environmental screening tool developed by the
              UNEP/OCHA Joint Environment Unit, USAID, UNHCR, NRC, IUCN, WWF and
              other partners.
            </p>
            <div className='flex gap-6'>
              <LinkBtn to='https://neatplus.org' />
              <LinkBtn to='https://github.com/NeatPlus/client' text='See Code' />
            </div>
          </div>
          <div className='lg:w-[45%] mb-[56px]'>
            <img src={lukim} alt='' className='max-h-[420px] mx-auto' />
            <h3 className='font-inter font-[600] text-[25px] leading-[64px] text-[#b41c4b] border-b-4 w-fit border-[#e8c24c]'>
              Lukim Gather Mobile
            </h3>
            <p className='my-[20px] font-inter font-[400] text-[16px] leading-[24px] text-color-text sm:text-[18px] sm:leading-[32px]'>
              A phone application being developed by Catalpa International which
              will allow Rangers, who work in isolated areas, to quickly record and
              report harmful and illegal activities.
            </p>
            <div className='flex gap-6'>
              <LinkBtn to='https://play.google.com/store/apps/details?id=org.lukimgather' />
              <LinkBtn to='https://github.com/nepware-dev/lukim-gather-mobile' text='See Code' />
            </div>
          </div>
          <div className='lg:w-[45%] mb-[56px]'>
            <img src={gbv} alt='' className='max-h-[420px] mx-auto' />
            <h3 className='font-inter font-[600] text-[25px] leading-[64px] text-[#b41c4b] border-b-4 w-fit border-[#e8c24c]'>
              GBV AoR
            </h3>
            <p className='my-[20px] font-inter font-[400] text-[16px] leading-[24px] text-color-text sm:text-[18px] sm:leading-[32px]'>
              GBV AoR is the global level forum for coordination and
              collaboration under the Cluster Approach on GBV prevention and
              response in humanitarian settings.
            </p>
            <div className='flex gap-6'>
              <LinkBtn to='https://gbvaor.net' />
              <LinkBtn to='https://github.com/nepware-dev/gbv' text='See Code' />
            </div>
          </div>
          <div className='lg:w-[45%] mb-[56px]'>
            <img src={deep} alt='' className='max-h-[420px] mx-auto' />
            <h3 className='font-inter font-[600] text-[25px] leading-[64px] text-[#b41c4b] border-b-4 w-fit border-[#e8c24c]'>
              The Deep
            </h3>
            <p className='my-[20px] font-inter font-[400] text-[16px] leading-[24px] text-color-text sm:text-[18px] sm:leading-[32px]'>
              An open-source collaborative platform for analysis that simplifies
              the workflow of design, data acquisition, analysis, communication
              and documentation.
            </p>
            <div className='flex gap-6'>
              <LinkBtn to='https://thedeep.io' />
              <LinkBtn to='https://github.com/the-deep/deep-website' text='See Code' />
            </div>
          </div>
          <div className='lg:w-[45%] mb-[56px]'>
            <img src={mentor} alt='' className='max-h-[420px] mx-auto' />
            <h3 className='font-inter font-[600] text-[25px] leading-[64px] text-[#b41c4b] border-b-4 w-fit border-[#e8c24c]'>
              Mentors Online
            </h3>
            <p className='my-[20px] font-inter font-[400] text-[16px] leading-[24px] text-color-text sm:text-[18px] sm:leading-[32px]'>
              Mentors Online Education is one of leading Entrance Preparation
              platforms in Nepal. Built from scratch with collaboration of top
              education experts in Nepal.
            </p>
            <LinkBtn to='https://mentors.com.np' />
          </div>
          <div className='lg:w-[45%] mb-[56px]'>
            <img src={upper} alt='' className='max-h-[420px] mx-auto' />
            <h3 className='font-inter font-[600] text-[25px] leading-[64px] text-[#b41c4b] border-b-4 w-fit border-[#e8c24c]'>
              Upper
            </h3>
            <p className='my-[20px] font-inter font-[400] text-[16px] leading-[24px] text-color-text sm:text-[18px] sm:leading-[32px]'>
              Upper is an intelligent generator of urban music clips. Users can
              Choose what to listen to in terms of genres and moods.
            </p>
            <LinkBtn to='https://play.google.com/store/apps/details?id=app.upper' />
          </div>
          <div className='lg:w-[45%] mb-[56px]'>
            <img src={mun} alt='' className='max-h-[420px] mx-auto' />
            <h3 className='font-inter font-[600] text-[25px] leading-[64px] text-[#b41c4b] border-b-4 w-fit border-[#e8c24c]'>
              Miss Universe Nepal
            </h3>
            <p className='my-[20px] font-inter font-[400] text-[16px] leading-[24px] text-color-text sm:text-[18px] sm:leading-[32px]'>
              Miss Universe Nepal (MUN) is a beauty pageant competition. The
              contest selects the country representative for Miss Universe.
            </p>
            <LinkBtn to='https://play.google.com/store/apps/details?id=org.missuniversenepal&hl=en&gl=US' />
          </div>
        </div>
      </div>
      <div
        className='max-w-[1440px] mx-auto px-[5vw] pt-[100px]'
        ref={contactRef}
      >
        <div>
          <div className='flex gap-[20px] justify-center'>
            <img src={contactIcon} alt='contact' className='max-h-[50px]' />
            <h2 className='self-end font-inter font-[500] text-[30px] text-[#693a70] text-center leading-[62px]'>
              CONTACT
            </h2>
          </div>
          <h2 className='font-sans font-[700] text-[36px] leading-[44px] text-[#b41c4b] lg:mt-[0] text-center sm:text-[48px] sm:leading-[52px]'>
            Say
            <span className='text-[#e8c24c]'> Hello</span>
          </h2>
          <p className='font-inter font-[600] text-[16px] sm:text-[20px] mt-[12px] sm:mt-[0] text-color-lt-grey mb-[56px] lg:mt-[0] text-center sm:leading-[52px]'>
            Submit the form below so I can get back to you as soon as possible
          </p>
          <div className='sm:w-[600px] lg:w-[100%] mx-auto flex flex-col lg:flex-row lg:items-center bg-[#e8c24c] rounded-3xl px-[5%] mt-[40px] pt-[50px] lg:pt-[0] gap-[40px] lg:gap-[20px]'>
            <div className='lg:w-[50%] flex flex-col gap-[20px]'>
              <Form />
            </div>
            <div className='lg:w-[50%] flex flex-col items-center gap-[15px] mb-[40px]'>
              <img src={contact} alt='' className='max-h-[420px]' />
              <div className='flex gap-[10px]'>
                <img src={map} alt='' className='max-h-[40px]' />
                <p className='font-inter sm:text-lg sm:font-[500] self-end'>
                  Address: Sunsari, Nepal
                </p>
              </div>
              <div className='flex gap-[10px]'>
                <img src={mobile} alt='' className='max-h-[40px]' />
                <p className='font-inter sm:text-lg sm:font-[500] self-end'>
                  Mobile: (+977) 9810440077
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </main>
  );
};

export default Home;
