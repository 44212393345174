/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useCallback} from 'react';
import {NavLink} from 'react-router-dom';

import {scrollTo} from '@utils/scroll';

import NavLogo from '@images/MyAvatar.png';
import github from '@images/github.png';
import freecodecamp from '@images/freecodecamp.png';

interface NavbarProps {
  aboutRef: React.RefObject<HTMLElement>;
  workRef: React.RefObject<HTMLElement>;
  contactRef: React.RefObject<HTMLElement>;
}

const LinkItem = ({to, title}: {to: any; title: string}) => {
  const handleClick = useCallback(() => {
    scrollTo(to.current.offsetTop);
  }, [to]);

  return (
    <NavLink to='' className='' onClick={handleClick}>
      <p className='text-[17px] sm:text-[18px] text-[#b41c4b] hover:text-[#693a70]'>{title}</p>
    </NavLink>
  );
};

const Navbar: React.FC<NavbarProps> = ({aboutRef, workRef, contactRef}) => {
  const handleLogoClick = useCallback(() => {
    scrollTo(0);
  }, []);

  return (
    <nav className='fixed top-0 left-0 right-0 bg-color-bg/[0.8] z-50'>
      <div className='max-w-[1440px] mx-auto px-[5vw] flex items-center justify-between gap-4 py-[10px]'>
        <NavLink
          to=''
          className='flex items-center gap-4'
          onClick={handleLogoClick}
        >
          <img src={NavLogo} alt='lukim-logo' className='h-[60px]' />
          <p className='hidden sm:flex font-sans font-[700] text-[26px] text-[#693a70]'>
            Saugat
            <span className='font-inter font-[400]'>Dhimal</span>
          </p>
        </NavLink>
        <div className='flex items-center gap-[15px] sm:gap-[30px]'>
          <LinkItem to={aboutRef} title='About' />
          <LinkItem to={workRef} title='Work' />
          <LinkItem to={contactRef} title='Contact' />
        </div>
        <div className='hidden md:flex flex-row gap-[20px]'>
          <a
            href='https://github.com/saugatdhimal'
            target='_blank'
            rel='noreferrer'
          >
            <img src={github} alt='' className='h-[40px] rounded-full' />
          </a>
          <a
            href='https://www.freecodecamp.org/saugat_dhimal'
            target='_blank'
            rel='noreferrer'
          >
            <img src={freecodecamp} alt='' className='h-[40px] rounded-full' />
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
