/* eslint-disable @typescript-eslint/no-empty-function */
import React, {useCallback} from 'react';
import {NavLink} from 'react-router-dom';

import {scrollTo} from '@utils/scroll';

import github from '@images/github.png';
import freecodecamp from '@images/freecodecamp.png';
import FooterLogo from '@images/MyAvatar.png';

const Footer = () => {
  const handleLogoClick = useCallback(() => {
    scrollTo(0);
  }, []);
  return (
    <footer>
      <div className='flex flex-col md:flex-row gap-[40px] md:gap-[0] items-center justify-between pb-[28px] mt-[80px] border-b-[1px] border-color-border'>
        <NavLink
          to=''
          className='flex items-center gap-4'
          onClick={handleLogoClick}
        >
          <img src={FooterLogo} alt='lukim-logo' className='h-[60px]' />
          <p className='font-sans font-[700] text-[26px] text-[#693a70]'>
            Saugat
            <span className='font-inter font-[400]'>Dhimal</span>
          </p>
        </NavLink>
        <div className='flex flex-row gap-[20px]'>
          <a
            href='https://github.com/saugatdhimal'
            target='_blank'
            rel='noreferrer'
          >
            <img src={github} alt='' className='h-[50px] rounded-full' />
          </a>
          <a
            href='https://www.freecodecamp.org/saugat_dhimal'
            target='_blank'
            rel='noreferrer'
          >
            <img src={freecodecamp} alt='' className='h-[50px] rounded-full' />
          </a>
        </div>
      </div>
      <p className='mt-[28px] mb-[50px] font-inter font-[400] text-color-lt-grey text-center'>
        Made with
        <span className='text-[22px] text-[red]'> ♥ </span>
        by Saugat Dhimal
      </p>
    </footer>
  );
};

export default Footer;
