import React, {useState, useCallback, useEffect} from 'react';
import emailjs from '@emailjs/browser';

const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const Form = () => {
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [msg, setMsg] = useState<string>('');
  const [err, setErr] = useState<boolean>(false);
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
  const [mailErr, setMailErr] = useState<boolean>(false);
  const [mailSuccess, setMailSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (err) setTimeout(() => setErr(false), 5000);
    if (invalidEmail) setTimeout(() => setInvalidEmail(false), 5000);
    if (mailErr) setTimeout(() => setMailErr(false), 5000);
    if (mailSuccess) setTimeout(() => setMailSuccess(false), 8000);
  }, [err, invalidEmail, mailErr, mailSuccess]);

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
    },
    [],
  );

  const handleEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    },
    [],
  );

  const handleMsgChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setMsg(e.target.value);
    },
    [],
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!name || !email || !msg) {
        setInvalidEmail(false);
        setErr(true);
      } else if (name && msg && email && email.match(validRegex)) {
        emailjs
          .send(
            'service_afs7ohd',
            'template_sdpd3x8',
            {name, email, msg},
            'd056Baa1wkuyOb8X-',
          )
          .then(
            (result) => {
              if (result) setMailSuccess(true);
              setName('');
              setEmail('');
              setMsg('');
            },
            (error) => {
              if (error) setMailErr(true);
            },
          );
      } else if (!email.match(validRegex)) {
        setErr(false);
        setInvalidEmail(true);
      }
    },
    [email, msg, name],
  );

  return (
    <>
      <div className='h-[20px] -mt-[20px]'>
        {err && (
          <p className='font-inter text-base text-color-red'>
            * All fields are required
          </p>
        )}
        {mailErr && (
          <p className='font-inter text-base text-color-red'>
            * Something went wrong, try again after sometime.
          </p>
        )}
        {mailSuccess && (
          <p className='font-inter text-[18px] text-color-green'>
            * Message was sent successfully
          </p>
        )}
        {invalidEmail && (
          <p className='font-inter text-base text-color-red'>
            * Please enter a valid email address
          </p>
        )}
      </div>
      <form className='flex flex-col gap-[10px] rounded-xl'>
        <input
          value={name}
          placeholder='Your name'
          onChange={handleNameChange}
          className='h-[50px] p-[20px] font-inter rounded-lg outline-[#693a70]'
        />
        <input
          type='email'
          required
          value={email}
          placeholder='Your email address'
          onChange={handleEmailChange}
          className='h-[50px] p-[20px] font-inter rounded-lg'
        />
        <textarea
          value={msg}
          onChange={handleMsgChange}
          placeholder='Write your message here'
          className='min-h-[120px] p-[20px] font-inter rounded-lg break-all'
        />
        <button
          type='button'
          className='h-[50px] sm:h-[58px] px-[25px] bg-[#ff0266] hover:bg-[#e8005c] font-[600] text-[17px] text-color-white font-inter rounded-lg'
          onClick={handleSubmit}
        >
          Send Message
        </button>
      </form>
    </>
  );
};

export default Form;
